.overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.75);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
}

.message {
    position: fixed;
    top: 50%;
    color: white;
    font-size: 2rem;
    font-weight: bold;
}

/* Additional styling for the text can be added here */
