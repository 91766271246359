.node {
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05); /* Subtle shadow */
  width: 800px; /* Fixed width or flexible based on content */
  color: #1A202C; /* Text color */
}

.content {
  pointer-events: none;
}

.handle {
  visibility: hidden;
}

.placeholder {
  width: 800px;
  background: #fff;
  border: 1px dashed #bbb;
  color: #bbb;
  box-shadow: none;
  height: 100px;
}
